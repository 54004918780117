import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { useReservContext } from '../../contexts/ReservContext';
import ProductDetailModal from '../Modal/Grade_3/ProductDetail/ProductDetailModal';
import { useAuth } from '../../contexts/AuthContext';
import Loading from '../../contexts/Loading'; // 로딩 중 표시 컴포넌트를 import 합니다.
import Tel from '../Common/Tel';
import NextInfo from '../Modal/Grade_3/NextInfo/NextInfo';

const SearchProduct = () => {
  const { hospitalKey, product, setProduct, setProductName, setProductState, setProductPrice, hospitalOriginKey } =
    useReservContext();
  const [productList, setProductList] = useState([]);
  const [hospitalProductList, setHospitalProductList] = useState([]);
  const navigation = useNavigate();
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const { decodeS0 } = useAuth();
  const inspectionState = useLocation();
  const [loading, setLoading] = useState(true);
  const firstsData = inspectionState.state?.status_hospital?.firsts;
  const [names, setNames] = useState([]);
  const [error, setError] = useState(null); // 에러 상태 추가
  const location = useLocation();
  const hospitalInfo = location.state?.status_hospital?.hospital;
  const [infoModalVisible, setInfoModalVisible] = useState(false); //보험 점검 후 검진 상세모달

  useEffect(() => {
    console.log('LOCATION 값:', location.state);
    const status = inspectionState.state?.status || '';
    if (status === '' && hospitalOriginKey === '' && firstsData !== '') {
      navigation('/reserv');
    }
    // console.log(hospitalInfo, 'hospitalInfo');
    getProductList();
    getHospitalProductList();
  }, []);

  const getProductList = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/reserv/product_list', {
        params: {
          key: hospitalOriginKey,
          loginId: decodeS0(),
        },
      });
      const allData = response.data.data || []; // 데이터가 없을 경우 빈 배열로 초기화
      setNames(response.data.names || []); // names도 기본값 설정
      // cost 값을 기준으로 정렬
      const sortedData = allData.sort((a, b) => a.cost - b.cost);
      setProductList(sortedData);
    } catch (error) {
      setError('상품 목록을 불러오는 데 실패했습니다.'); // 에러 메시지 설정
      console.error('Error fetching product list:', error);
    } finally {
      setLoading(false); // 로딩 완료
    }
  };

  //병원먼저 선택하고 예약진행할 때
  const getHospitalProductList = async () => {
    try {
      const response = await Axios.get('https://www.wlcmanager.com:8443/api/get/reserv/hospital_product_list', {
        params: {
          hospitalInfo: hospitalInfo.name,
          loginID: decodeS0(), // key 값을 제거하고 loginID만 전달
        },
      });
      const allData = response.data.data || []; // 데이터가 없을 경우 빈 배열로 초기화
      setHospitalProductList(allData);
    } catch (error) {
      setError('병원 상품 목록을 불러오는 데 실패했습니다.'); // 에러 메시지 설정
      // console.error('Error fetching hospital product list:', error);
    } finally {
      setLoading(false); // 로딩 완료
    }
  };

  // console.log('hospitalProductList', hospitalProductList);

  //상품찾기 > 보험점검 후 검진 선택
  const selectProduct = (data) => {
    console.log('보험점검후 검진: Product Data:', data);
    console.log('보험점검후 검진: Product Name:', data.product_1);

    setProduct(data.p_key);
    setProductName(data.product_1);
    setProductState('1');
    setProductPrice(data.cost);

    if (firstsData === '1') {
      navigation('/reserv/date');
    } else {
      navigation('/reserv/hospital', {
        state: {
          categoryStatus: inspectionState.state.status || '',
        },
      });
    }
  };

  //상품찾기 > 일반 검진 선택
  const selectNomalProduct = (data) => {
    console.log('일반 검진: Product Data:', data);
    console.log('일반 검진: Product Name:', data.product_1);

    setProduct(data.p_key);
    setProductName(data.product_1);
    setProductPrice(data.normal_cost);
    setProductState('2');
    if (firstsData === '1') {
      navigation('/reserv/date2');
    } else {
      navigation('/reserv/hospital', {
        state: {
          categoryStatus: inspectionState.state.status || '',
        },
      });
    }
  };

  //병원먼저
  const selectProductHospitalFirst = (data) => {
    console.log('병원보기 > 보험점검 후 검진: Product Data:', data);
    console.log('병원보기 > 보험점검 후 검진: Product Name:', data.product);

    setProduct(data.p_key);
    setProductName(data.product);
    setProductState('1');
    setProductPrice(data.cost);

    if (firstsData === '1') {
      navigation('/reserv/date');
    } else {
      navigation('/reserv/hospital', {
        state: {
          categoryStatus: inspectionState.state.status || '',
        },
      });
    }
  };

  //병원먼저
  const selectNomalProductHospitalFirst = (data) => {
    console.log('병원보기 > 일반 검진: Product Data:', data);
    console.log('병원보기 > 일반 검진: Product Name:', data.product);

    setProduct(data.p_key);
    setProductName(data.product);
    setProductPrice(data.normal_cost);
    setProductState('2');
    if (firstsData === '1') {
      navigation('/reserv/date2');
    } else {
      navigation('/reserv/hospital', {
        state: {
          categoryStatus: inspectionState.state.status || '',
        },
      });
    }
  };

  // ProductDetail모달
  const openModal = (data) => {
    setModal(!modal);
    setModalData(data);
  };

  // 보험 점검 후 검진 안내모달
  const toggleInfoModal = () => {
    setInfoModalVisible(!infoModalVisible);
  };

  const filteredProductList = productList.filter((data) => {
    const isValidCost = (() => {
      const cost = Number(data.cost); // cost를 숫자로 변환
      //국가검진
      if (inspectionState.state && inspectionState.state.status === '1') {
        return data.product_1 === '국가검진';
        //일반종합검진
      } else if (inspectionState.state && inspectionState.state.status === '2') {
        return data.type === '2';
        //신부 패키지
      } else if (inspectionState.state && inspectionState.state.status === '3') {
        return data.type === '3';
        //효도상품
      } else if (inspectionState.state && inspectionState.state.status === '4') {
        return data.type === '4';
      } else {
        return hospitalProductList.some((item) => item.p_key === data.p_key);
      }
    })();

    const isNameMatch = names.includes(data.product_1);
    const isHospitalProduct = hospitalProductList.some((item) => item.p_key === data.p_key);

    // 중복 제거: 병원 상품 리스트에 포함되지 않거나 일반 상품일 경우
    const isNotDuplicate = !isHospitalProduct;

    return isValidCost && isNameMatch && isNotDuplicate; // 세 조건 모두 만족해야 포함
  });

  //console.log('filteredProductList', filteredProductList);

  // console.log('hospitalProductList', hospitalProductList);

  const handleBack = () => {
    setProduct('');
    setProductState('');
    navigation(-1);
  };

  return (
    <div className="reserv_wrap">
      {infoModalVisible && <NextInfo onClose={toggleInfoModal} />}
      {loading ? (
        <Loading />
      ) : (
        <>
          {error && <div className="error-message">{error}</div>}
          <div className="back_btn_box">
            <div className="back_btn" onClick={() => handleBack()}>
              뒤로 이동
            </div>
          </div>
          <div className="reserv_back product">
            <div className="reserv_top_box">
              <div className="reserv_title">상품 찾기</div>
              <div className="reserv_title sub">원하시는 상품을 확인하고 선택합니다.</div>
            </div>

            {/* 병원 상품 리스트 */}
            {hospitalInfo && hospitalProductList.length > 0 && (
              <div className="reserv_bottom_box">
                <div className="slide_wrap">
                  <h3>병원 상품 리스트</h3>
                  {hospitalProductList.map((data, index) => (
                    <div className="product_box color" key={index}>
                      <div className="inner_bottom_box">
                        <div className="product_text_box">
                          <div className="product_tab1">
                            {/* 상품 이미지 부분 (이미지 경로는 필요에 따라 수정하세요) */}
                            <div>asd</div>
                            <div className={`product_img img_${index}`}></div>
                            <div className="product_text title">{data.product}</div>
                            <div className="product_info_text">{data.name}</div>{' '}
                            {/* 병원 이름을 상품 정보 텍스트에 추가 */}
                          </div>
                          <div className="product_tab2">
                            <div className="product_text og_price">
                              <div className="product-flex-wrap next" onClick={toggleInfoModal}>
                                <div className="product-information"></div>
                                <span>보험 점검 후 검진이란?</span>
                              </div>
                              {data.type !== '3' && data.type !== '4' && (
                                <div className="product_txt1" onClick={() => selectProductHospitalFirst(data)}>
                                  <div>☞ 보험 점검 후 검진</div>
                                  <div>₩{data.cost}</div>
                                </div>
                              )}
                              <div className="product_txt2" onClick={() => selectNomalProductHospitalFirst(data)}>
                                <div>☞ 일반 검진</div>
                                <div>₩{data.normal_cost}</div>
                              </div>

                              <div className="product_txt3" onClick={() => openModal(data)}>
                                상품 상세보기
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* 일반 상품 리스트 */}
            <div className="reserv_bottom_box">
              <div className="slide_wrap">
                {filteredProductList.length > 0
                  ? filteredProductList.map((data, index) => (
                      <div className="product_box color" key={index}>
                        <div className="inner_bottom_box">
                          <div className="product_text_box">
                            <div className="product_tab1">
                              {/* {data.product_1 === '프리미엄 패키지' ? (
                                <div className="sel-boxx">
                                  <div class="premium-skin"></div>
                                </div>
                              ) : null}

                              {data.product_1 === '스마트플러스' || data.product_1 === '프리미엄검진+스킨케어' ? (
                                <div className="sel-boxx">
                                  <div class="smtpre-skin"></div>
                                </div>
                              ) : null}
                               */}
                              <div
                                className={`product_img ${
                                  Number(data.cost) >= 1000000
                                    ? `top_img_${index}`
                                    : Number(data.cost) === 0
                                    ? `sla_img_${index}`
                                    : `img_${index}`
                                }`}
                              ></div>
                              <div className="product_text title">{data.product_1}</div>
                              <div className="product_info_text">
                                <span>상품가격 및 세부 검사내역은 병원마다 상이할 수 있습니다.</span>
                              </div>
                            </div>
                            <div className="product_tab2">
                              <div className="product_text og_price">
                                <div className="product-flex-wrap next" onClick={toggleInfoModal}>
                                  <div className="product-information"></div>
                                  <span>보험 점검 후 검진이란?</span>
                                </div>
                                {data.type !== '3' && data.type !== '4' && (
                                  <div className="product_txt1" onClick={() => selectProduct(data)}>
                                    <div>☞ 보험 점검 후 검진</div>
                                    <div>₩{Number(data.cost).toLocaleString()}</div>
                                  </div>
                                )}
                                {/* <div className="product-flex-wrap normal">
                                  <div className="product-information"></div>
                                  <span>일반 검진이란?</span>
                                </div> */}
                                <div className="product_txt2" onClick={() => selectNomalProduct(data)}>
                                  <div>☞ 일반 검진</div>
                                  <div>₩{Number(data.normal_cost).toLocaleString()}</div>
                                </div>
                                <div className="product_txt3" onClick={() => openModal(data)}>
                                  상품 상세보기
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : hospitalProductList.length === 0 && ( // 병원 상품 리스트가 비어있을 때만 메시지 표시
                      <div className="no-product">
                        해당 지점에 등록된 판매상품이 존재하지 않습니다. <br />※ 관리자에게 문의바랍니다.
                      </div>
                    )}
              </div>
            </div>
            <Tel />
          </div>
        </>
      )}
      {modal && <ProductDetailModal closeModal={() => setModal(false)} modalData={modalData} />}
    </div>
  );
};

export default SearchProduct;
