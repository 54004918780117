import React, { useEffect, useState } from 'react';

const ProductDetailModal = (props) => {
  const [data, setData] = useState([]);

  console.log('data', data);

  useEffect(() => {
    if (props.modalData) {
      setData(props.modalData);
    }
  }, [props.modalData]);

  //프로그램별 검진항목 PDF다운로드
  const downloadFile = () => {
    let filePath = '';

    const products = data.product_1 || data.product;

    switch (products) {
      case '예비부부 패키지 1인':
      case '예비부부 패키지 2인':
      case '효도검진 패키지 1인':
      case '효도검진 패키지 2인':
        filePath = '/files/신혼, 효도 패키지 검진항목.pdf';
        break;

      case '페이스 라인업 수분 케어':
      case '백옥주사':
      case '텐써마':
      case '드레스 라인 보톡스':
      case '더마샤인(리쥬란+물광주사+스킨보톡스)':
      case '항노화-오투셀(광양자치료)':
      case '상담후 진료(신부패키지)':
      case '상담후 진료(효도상품)':
        filePath = '/files/뷰티 항노화 항목.pdf';
        break;

      case '스마트플러스':
        filePath = '/files/스마트플러스 상세.pdf';
        break;

      case '프리미엄검진+스킨케어':
        filePath = '/files/프리미엄검진스킨케어 상세.pdf';
        break;

      default:
        filePath = '/files/프로그램별 검진항목.pdf';
        break;
    }

    // Create an anchor element
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.split('/').pop(); // Set the file name for download
    link.click(); // Trigger the click event to start download
  };

  return (
    <div className="modal_wrap product" style={{ overflow: 'hidden', maxHeight: '100vh' }}>
      <div className="modal_back product" style={{ overflow: 'hidden' }}>
        <div className="modal_box" style={{ overflow: 'hidden', maxHeight: '100vh' }}>
          <div className="modal_title_box">
            <div className="modal_text title">{data.product_1}</div>
            {/* {data.p_key === "2" ? (
              <div className="modal_text">
                {" "}
                {Number(data.cost * 2).toLocaleString()}원 (2인)
              </div>
            ) : (
              <div className="modal_text">
                {Number(data.cost).toLocaleString()}원 (1인)
              </div>
            )} */}
            <div className="modal_text sub">
              {data.type !== '3' && data.type !== '4' && <span></span>}
              {(data.type === '3' || data.type === '4') && <span></span>}
            </div>
          </div>
          <div className="modal_content_box" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 220px)' }}>
            {data.product_1 === 'VIP 패키지' || data.product === 'VIP 패키지' ? (
              <div class="disease-info">
                <h1 class="title">검사별로 확인 가능한 핵심 질병</h1>

                <div class="section">
                  <h2 class="section-header">영상 검사</h2>

                  <h3 class="sub-header">CT</h3>
                  <ul class="list">
                    <li>폐질환: 폐암, 폐렴, 폐기종</li>
                    <li>복부질환: 간암, 췌장암, 신장암, 담석증</li>
                    <li>뇌질환: 두부 외상, 뇌출혈</li>
                    <li>골격질환: 골절, 척추질환</li>
                  </ul>

                  <h3 class="sub-header">특수초음파</h3>
                  <ul class="list">
                    <li>심혈관: 심장판막질환, 심근경색, 심부전</li>
                    <li>혈관: 동맥류, 하지정맥류</li>
                    <li>복부: 간경변, 담낭염</li>
                  </ul>

                  <h3 class="sub-header">일반초음파</h3>
                  <ul class="list">
                    <li>갑상선: 갑상선 결절, 갑상선암</li>
                    <li>복부: 간암, 신장암, 췌장암</li>
                    <li>여성 건강: 자궁근종, 난소낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">내시경 검사</h2>

                  <h3 class="sub-header">위내시경</h3>
                  <ul class="list">
                    <li>위염, 위궤양, 위암, 헬리코박터 감염</li>
                  </ul>

                  <h3 class="sub-header">대장내시경</h3>
                  <ul class="list">
                    <li>대장용종, 대장암, 크론병, 궤양성 대장염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">혈액 검사 및 암표지자</h2>

                  <h3 class="sub-header">암표지자 검사</h3>
                  <ul class="list">
                    <li>간암: AFP</li>
                    <li>췌장암: CA19-9</li>
                    <li>대장암: CEA</li>
                    <li>유방암: CA15-3</li>
                    <li>난소암: CA125</li>
                    <li>폐암: Cyfra 21-1</li>
                  </ul>

                  <h3 class="sub-header">일반혈액검사</h3>
                  <ul class="list">
                    <li>빈혈: 철결핍성 빈혈, 재생불량성 빈혈</li>
                    <li>염증반응: 감염, 류마티스 관절염</li>
                  </ul>

                  <h3 class="sub-header">염증반응 검사</h3>
                  <ul class="list">
                    <li>전신 염증: 패혈증, 감염성 질환</li>
                    <li>자가면역질환: 루푸스, 류마티스 질환</li>
                  </ul>

                  <h3 class="sub-header">췌장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 췌장염</li>
                    <li>췌장암</li>
                  </ul>

                  <h3 class="sub-header">당뇨 검사</h3>
                  <ul class="list">
                    <li>당뇨병: 제1형/제2형 당뇨병</li>
                    <li>당뇨 합병증: 당뇨병성 신증</li>
                  </ul>

                  <h3 class="sub-header">신장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 신부전</li>
                    <li>신장염, 요로감염</li>
                  </ul>

                  <h3 class="sub-header">전해질 검사</h3>
                  <ul class="list">
                    <li>전해질 불균형: 저칼륨혈증, 저나트륨혈증</li>
                    <li>심혈관 질환: 부정맥</li>
                  </ul>

                  <h3 class="sub-header">갑상선 검사</h3>
                  <ul class="list">
                    <li>갑상선기능항진증, 갑상선기능저하증</li>
                    <li>갑상선염</li>
                  </ul>

                  <h3 class="sub-header">간기능 검사</h3>
                  <ul class="list">
                    <li>간염: A형, B형, C형</li>
                    <li>간경변, 지방간</li>
                    <li>간암</li>
                  </ul>

                  <h3 class="sub-header">성병 검사</h3>
                  <ul class="list">
                    <li>매독, HIV, 임질, 클라미디아</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">일반 검사</h2>

                  <h3 class="sub-header">기본진료</h3>
                  <ul class="list">
                    <li>고혈압, 당뇨, 비만, 고지혈증</li>
                  </ul>

                  <h3 class="sub-header">안과</h3>
                  <ul class="list">
                    <li>백내장, 녹내장, 황반변성</li>
                  </ul>

                  <h3 class="sub-header">이과</h3>
                  <ul class="list">
                    <li>중이염, 난청, 메니에르병</li>
                  </ul>

                  <h3 class="sub-header">순환기</h3>
                  <ul class="list">
                    <li>고혈압, 심부전, 관상동맥질환</li>
                  </ul>

                  <h3 class="sub-header">심폐기능 검사</h3>
                  <ul class="list">
                    <li>폐기능 저하: 만성폐쇄성폐질환(COPD), 천식</li>
                    <li>심장질환: 협심증, 심근경색</li>
                  </ul>

                  <h3 class="sub-header">골밀도 검사</h3>
                  <ul class="list">
                    <li>골다공증, 골감소증</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">여성 건강</h2>

                  <h3 class="sub-header">자궁경부세포 검사</h3>
                  <ul class="list">
                    <li>자궁경부암, 자궁경부염</li>
                  </ul>

                  <h3 class="sub-header">유방촬영술</h3>
                  <ul class="list">
                    <li>유방암, 유방낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">소변 검사</h2>

                  <h3 class="sub-header">소변검사</h3>
                  <ul class="list">
                    <li>요로감염, 신장염, 당뇨병</li>
                    <li>단백뇨, 혈뇨</li>
                  </ul>

                  <h3 class="sub-header">요침사현미경 검사</h3>
                  <ul class="list">
                    <li>신장결석, 사구체신염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">근골격계 검사</h2>
                  <ul class="list">
                    <li>관절염, 골관절염</li>
                    <li>근막통증증후군</li>
                  </ul>
                </div>

                <p class="note">
                  위의 질환 목록은 검사 결과에 따라 의심되거나 진단될 수 있는 대표적인 질병들입니다. 정확한 진단은
                  임상의와 상담을 통해 이루어져야 합니다.
                </p>
              </div>
            ) : data.product_1 === '스마트 패키지' || data.product_1 === '스마트플러스' ? (
              <div class="disease-info">
                <h1 class="title">검사별로 확인 가능한 핵심 질병</h1>

                <div class="section">
                  <h2 class="section-header">영상 검사</h2>

                  <h3 class="sub-header">CT</h3>
                  <ul class="list">
                    <li>폐질환: 폐암, 폐렴, 폐기종</li>
                    <li>복부질환: 간암, 췌장암, 신장암, 담석증</li>
                    <li>뇌질환: 두부 외상, 뇌출혈</li>
                    <li>골격질환: 골절, 척추질환</li>
                  </ul>

                  <h3 class="sub-header">일반초음파</h3>
                  <ul class="list">
                    <li>갑상선: 갑상선 결절, 갑상선암</li>
                    <li>복부: 간암, 신장암, 췌장암</li>
                    <li>여성 건강: 자궁근종, 난소낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">내시경 검사</h2>

                  <h3 class="sub-header">위내시경</h3>
                  <ul class="list">
                    <li>위염, 위궤양, 위암, 헬리코박터 감염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">혈액 검사 및 암표지자</h2>

                  <h3 class="sub-header">일반혈액검사</h3>
                  <ul class="list">
                    <li>빈혈: 철결핍성 빈혈, 재생불량성 빈혈</li>
                    <li>염증반응: 감염, 류마티스 관절염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">일반 검사</h2>

                  <h3 class="sub-header">기본진료</h3>
                  <ul class="list">
                    <li>고혈압, 당뇨, 비만, 고지혈증</li>
                  </ul>

                  <h3 class="sub-header">안과</h3>
                  <ul class="list">
                    <li>백내장, 녹내장, 황반변성</li>
                  </ul>

                  <h3 class="sub-header">이과</h3>
                  <ul class="list">
                    <li>중이염, 난청, 메니에르병</li>
                  </ul>

                  <h3 class="sub-header">순환기</h3>
                  <ul class="list">
                    <li>고혈압, 심부전, 관상동맥질환</li>
                  </ul>

                  <h3 class="sub-header">심폐기능 검사</h3>
                  <ul class="list">
                    <li>폐기능 저하: 만성폐쇄성폐질환(COPD), 천식</li>
                    <li>심장질환: 협심증, 심근경색</li>
                  </ul>

                  <h3 class="sub-header">골밀도 검사</h3>
                  <ul class="list">
                    <li>골다공증, 골감소증</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">여성 건강</h2>

                  <h3 class="sub-header">자궁경부세포 검사</h3>
                  <ul class="list">
                    <li>자궁경부암, 자궁경부염</li>
                  </ul>

                  <h3 class="sub-header">유방촬영술</h3>
                  <ul class="list">
                    <li>유방암, 유방낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">소변 검사</h2>

                  <h3 class="sub-header">소변검사</h3>
                  <ul class="list">
                    <li>요로감염, 신장염, 당뇨병</li>
                    <li>단백뇨, 혈뇨</li>
                  </ul>

                  <h3 class="sub-header">요침사현미경 검사</h3>
                  <ul class="list">
                    <li>신장결석, 사구체신염</li>
                  </ul>
                </div>

                <p class="note">
                  위의 질환 목록은 검사 결과에 따라 의심되거나 진단될 수 있는 대표적인 질병들입니다. 정확한 진단은
                  임상의와 상담을 통해 이루어져야 합니다.
                </p>
              </div>
            ) : data.product_1 === '프리미엄 패키지' || data.product === '프리미엄 패키지' ? (
              <div class="disease-info">
                <h1 class="title">검사별로 확인 가능한 핵심 질병</h1>

                <div class="section">
                  <h2 class="section-header">영상 검사</h2>

                  <h3 class="sub-header">CT</h3>
                  <ul class="list">
                    <li>폐질환: 폐암, 폐렴, 폐기종</li>
                    <li>복부질환: 간암, 췌장암, 신장암, 담석증</li>
                    <li>뇌질환: 두부 외상, 뇌출혈</li>
                    <li>골격질환: 골절, 척추질환</li>
                  </ul>

                  <h3 class="sub-header">일반초음파</h3>
                  <ul class="list">
                    <li>갑상선: 갑상선 결절, 갑상선암</li>
                    <li>복부: 간암, 신장암, 췌장암</li>
                    <li>여성 건강: 자궁근종, 난소낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">내시경 검사</h2>

                  <h3 class="sub-header">위내시경</h3>
                  <ul class="list">
                    <li>위염, 위궤양, 위암, 헬리코박터 감염</li>
                  </ul>

                  <h3 class="sub-header">대장내시경</h3>
                  <ul class="list">
                    <li>대장용종, 대장암, 크론병, 궤양성 대장염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">혈액 검사 및 암표지자</h2>

                  <h3 class="sub-header">일반혈액검사</h3>
                  <ul class="list">
                    <li>빈혈: 철결핍성 빈혈, 재생불량성 빈혈</li>
                    <li>염증반응: 감염, 류마티스 관절염</li>
                  </ul>

                  <h3 class="sub-header">염증반응 검사</h3>
                  <ul class="list">
                    <li>전신 염증: 패혈증, 감염성 질환</li>
                    <li>자가면역질환: 루푸스, 류마티스 질환</li>
                  </ul>

                  <h3 class="sub-header">췌장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 췌장염</li>
                    <li>췌장암</li>
                  </ul>

                  <h3 class="sub-header">당뇨 검사</h3>
                  <ul class="list">
                    <li>당뇨병: 제1형/제2형 당뇨병</li>
                    <li>당뇨 합병증: 당뇨병성 신증</li>
                  </ul>

                  <h3 class="sub-header">신장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 신부전</li>
                    <li>신장염, 요로감염</li>
                  </ul>

                  <h3 class="sub-header">전해질 검사</h3>
                  <ul class="list">
                    <li>전해질 불균형: 저칼륨혈증, 저나트륨혈증</li>
                    <li>심혈관 질환: 부정맥</li>
                  </ul>

                  <h3 class="sub-header">갑상선 검사</h3>
                  <ul class="list">
                    <li>갑상선기능항진증, 갑상선기능저하증</li>
                    <li>갑상선염</li>
                  </ul>

                  <h3 class="sub-header">간기능 검사</h3>
                  <ul class="list">
                    <li>간염: A형, B형, C형</li>
                    <li>간경변, 지방간</li>
                    <li>간암</li>
                  </ul>

                  <h3 class="sub-header">성병 검사</h3>
                  <ul class="list">
                    <li>매독, HIV, 임질, 클라미디아</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">일반 검사</h2>

                  <h3 class="sub-header">기본진료</h3>
                  <ul class="list">
                    <li>고혈압, 당뇨, 비만, 고지혈증</li>
                  </ul>

                  <h3 class="sub-header">안과</h3>
                  <ul class="list">
                    <li>백내장, 녹내장, 황반변성</li>
                  </ul>

                  <h3 class="sub-header">이과</h3>
                  <ul class="list">
                    <li>중이염, 난청, 메니에르병</li>
                  </ul>

                  <h3 class="sub-header">순환기</h3>
                  <ul class="list">
                    <li>고혈압, 심부전, 관상동맥질환</li>
                  </ul>

                  <h3 class="sub-header">심폐기능 검사</h3>
                  <ul class="list">
                    <li>폐기능 저하: 만성폐쇄성폐질환(COPD), 천식</li>
                    <li>심장질환: 협심증, 심근경색</li>
                  </ul>

                  <h3 class="sub-header">골밀도 검사</h3>
                  <ul class="list">
                    <li>골다공증, 골감소증</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">여성 건강</h2>

                  <h3 class="sub-header">자궁경부세포 검사</h3>
                  <ul class="list">
                    <li>자궁경부암, 자궁경부염</li>
                  </ul>

                  <h3 class="sub-header">유방촬영술</h3>
                  <ul class="list">
                    <li>유방암, 유방낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">소변 검사</h2>

                  <h3 class="sub-header">소변검사</h3>
                  <ul class="list">
                    <li>요로감염, 신장염, 당뇨병</li>
                    <li>단백뇨, 혈뇨</li>
                  </ul>

                  <h3 class="sub-header">요침사현미경 검사</h3>
                  <ul class="list">
                    <li>신장결석, 사구체신염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">근골격계 검사</h2>
                  <ul class="list">
                    <li>관절염, 골관절염</li>
                    <li>근막통증증후군</li>
                  </ul>
                </div>

                <p class="note">
                  위의 질환 목록은 검사 결과에 따라 의심되거나 진단될 수 있는 대표적인 질병들입니다. 정확한 진단은
                  임상의와 상담을 통해 이루어져야 합니다.
                </p>
              </div>
            ) : data.product_1 === '노블레스 패키지' ||
              data.product === '노블레스 패키지' ||
              data.product_1 === '프리미엄검진+스킨케어' ? (
              <div class="disease-info">
                <h1 class="title">검사별로 확인 가능한 핵심 질병</h1>

                <div class="section">
                  <h2 class="section-header">영상 검사</h2>

                  <h3 class="sub-header">MRI</h3>
                  <ul class="list">
                    <li>뇌질환: 뇌종양, 뇌경색, 뇌출혈, 뇌동맥류</li>
                    <li>척추질환: 디스크 탈출증, 척수종양</li>
                    <li>근골격계: 인대 손상, 관절염, 근육 질환</li>
                  </ul>

                  <h3 class="sub-header">MRA</h3>
                  <ul class="list">
                    <li>혈관질환: 뇌동맥류, 동정맥 기형, 동맥경화, 뇌혈관 협착</li>
                  </ul>

                  <h3 class="sub-header">CT</h3>
                  <ul class="list">
                    <li>폐질환: 폐암, 폐렴, 폐기종</li>
                    <li>복부질환: 간암, 췌장암, 신장암, 담석증</li>
                    <li>뇌질환: 두부 외상, 뇌출혈</li>
                    <li>골격질환: 골절, 척추질환</li>
                  </ul>

                  <h3 class="sub-header">특수초음파</h3>
                  <ul class="list">
                    <li>심혈관: 심장판막질환, 심근경색, 심부전</li>
                    <li>혈관: 동맥류, 하지정맥류</li>
                    <li>복부: 간경변, 담낭염</li>
                  </ul>

                  <h3 class="sub-header">일반초음파</h3>
                  <ul class="list">
                    <li>갑상선: 갑상선 결절, 갑상선암</li>
                    <li>복부: 간암, 신장암, 췌장암</li>
                    <li>여성 건강: 자궁근종, 난소낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">내시경 검사</h2>

                  <h3 class="sub-header">위내시경</h3>
                  <ul class="list">
                    <li>위염, 위궤양, 위암, 헬리코박터 감염</li>
                  </ul>

                  <h3 class="sub-header">대장내시경</h3>
                  <ul class="list">
                    <li>대장용종, 대장암, 크론병, 궤양성 대장염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">혈액 검사 및 암표지자</h2>

                  <h3 class="sub-header">암표지자 검사</h3>
                  <ul class="list">
                    <li>간암: AFP</li>
                    <li>췌장암: CA19-9</li>
                    <li>대장암: CEA</li>
                    <li>유방암: CA15-3</li>
                    <li>난소암: CA125</li>
                    <li>폐암: Cyfra 21-1</li>
                  </ul>

                  <h3 class="sub-header">일반혈액검사</h3>
                  <ul class="list">
                    <li>빈혈: 철결핍성 빈혈, 재생불량성 빈혈</li>
                    <li>염증반응: 감염, 류마티스 관절염</li>
                  </ul>

                  <h3 class="sub-header">염증반응 검사</h3>
                  <ul class="list">
                    <li>전신 염증: 패혈증, 감염성 질환</li>
                    <li>자가면역질환: 루푸스, 류마티스 질환</li>
                  </ul>

                  <h3 class="sub-header">췌장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 췌장염</li>
                    <li>췌장암</li>
                  </ul>

                  <h3 class="sub-header">당뇨 검사</h3>
                  <ul class="list">
                    <li>당뇨병: 제1형/제2형 당뇨병</li>
                    <li>당뇨 합병증: 당뇨병성 신증</li>
                  </ul>

                  <h3 class="sub-header">신장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 신부전</li>
                    <li>신장염, 요로감염</li>
                  </ul>

                  <h3 class="sub-header">전해질 검사</h3>
                  <ul class="list">
                    <li>전해질 불균형: 저칼륨혈증, 저나트륨혈증</li>
                    <li>심혈관 질환: 부정맥</li>
                  </ul>

                  <h3 class="sub-header">갑상선 검사</h3>
                  <ul class="list">
                    <li>갑상선기능항진증, 갑상선기능저하증</li>
                    <li>갑상선염</li>
                  </ul>

                  <h3 class="sub-header">간기능 검사</h3>
                  <ul class="list">
                    <li>간염: A형, B형, C형</li>
                    <li>간경변, 지방간</li>
                    <li>간암</li>
                  </ul>

                  <h3 class="sub-header">성병 검사</h3>
                  <ul class="list">
                    <li>매독, HIV, 임질, 클라미디아</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">일반 검사</h2>

                  <h3 class="sub-header">기본진료</h3>
                  <ul class="list">
                    <li>고혈압, 당뇨, 비만, 고지혈증</li>
                  </ul>

                  <h3 class="sub-header">만성질환 관리</h3>
                  <ul class="list">
                    <li>충치, 치주염, 구강암</li>
                  </ul>

                  <h3 class="sub-header">안과</h3>
                  <ul class="list">
                    <li>백내장, 녹내장, 황반변성</li>
                  </ul>

                  <h3 class="sub-header">이과</h3>
                  <ul class="list">
                    <li>중이염, 난청, 메니에르병</li>
                  </ul>

                  <h3 class="sub-header">순환기</h3>
                  <ul class="list">
                    <li>고혈압, 심부전, 관상동맥질환</li>
                  </ul>

                  <h3 class="sub-header">심폐기능 검사</h3>
                  <ul class="list">
                    <li>폐기능 저하: 만성폐쇄성폐질환(COPD), 천식</li>
                    <li>심장질환: 협심증, 심근경색</li>
                  </ul>

                  <h3 class="sub-header">골밀도 검사</h3>
                  <ul class="list">
                    <li>골다공증, 골감소증</li>
                  </ul>

                  <h3 class="sub-header">스트레스 검사</h3>
                  <ul class="list">
                    <li>자율신경계 이상, 스트레스성 질환</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">여성 건강</h2>

                  <h3 class="sub-header">자궁경부세포 검사</h3>
                  <ul class="list">
                    <li>자궁경부암, 자궁경부염</li>
                  </ul>

                  <h3 class="sub-header">유방촬영술</h3>
                  <ul class="list">
                    <li>유방암, 유방낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">소변 검사</h2>

                  <h3 class="sub-header">소변검사</h3>
                  <ul class="list">
                    <li>요로감염, 신장염, 당뇨병</li>
                    <li>단백뇨, 혈뇨</li>
                  </ul>

                  <h3 class="sub-header">요침사현미경 검사</h3>
                  <ul class="list">
                    <li>신장결석, 사구체신염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">근골격계 검사</h2>

                  <h3 class="sub-header">근골격계 검사</h3>
                  <ul class="list">
                    <li>관절염, 골관절염</li>
                    <li>근막통증증후군</li>
                  </ul>
                </div>

                <p class="note">
                  위의 질환 목록은 검사 결과에 따라 의심되거나 진단될 수 있는 대표적인 질병들입니다. 정확한 진단은
                  임상의와 상담을 통해 이루어져야 합니다.
                </p>
              </div>
            ) : data.product_1 === '예비부부 패키지 1인' || data.product === '예비부부 패키지 1인' ? (
              <div class="disease-info">
                <h1 class="title">검사별로 확인 가능한 핵심 질병</h1>

                <div class="section">
                  <h2 class="section-header">영상 검사</h2>

                  <h3 class="sub-header">CT</h3>
                  <ul class="list">
                    <li>폐질환: 폐암, 폐렴, 폐기종</li>
                    <li>복부질환: 간암, 췌장암, 신장암, 담석증</li>
                    <li>뇌질환: 두부 외상, 뇌출혈</li>
                    <li>골격질환: 골절, 척추질환</li>
                  </ul>

                  <h3 class="sub-header">일반초음파</h3>
                  <ul class="list">
                    <li>갑상선: 갑상선 결절, 갑상선암</li>
                    <li>복부: 간암, 신장암, 췌장암</li>
                    <li>여성 건강: 자궁근종, 난소낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">내시경 검사</h2>

                  <h3 class="sub-header">위내시경</h3>
                  <ul class="list">
                    <li>위염, 위궤양, 위암, 헬리코박터 감염</li>
                  </ul>

                  <h3 class="sub-header">대장내시경</h3>
                  <ul class="list">
                    <li>대장용종, 대장암, 크론병, 궤양성 대장염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">혈액 검사 및 암표지자</h2>

                  <h3 class="sub-header">일반혈액검사</h3>
                  <ul class="list">
                    <li>빈혈: 철결핍성 빈혈, 재생불량성 빈혈</li>
                    <li>염증반응: 감염, 류마티스 관절염</li>
                  </ul>

                  <h3 class="sub-header">염증반응 검사</h3>
                  <ul class="list">
                    <li>전신 염증: 패혈증, 감염성 질환</li>
                    <li>자가면역질환: 루푸스, 류마티스 질환</li>
                  </ul>

                  <h3 class="sub-header">췌장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 췌장염</li>
                    <li>췌장암</li>
                  </ul>

                  <h3 class="sub-header">당뇨 검사</h3>
                  <ul class="list">
                    <li>당뇨병: 제1형/제2형 당뇨병</li>
                    <li>당뇨 합병증: 당뇨병성 신증</li>
                  </ul>

                  <h3 class="sub-header">신장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 신부전</li>
                    <li>신장염, 요로감염</li>
                  </ul>

                  <h3 class="sub-header">전해질 검사</h3>
                  <ul class="list">
                    <li>전해질 불균형: 저칼륨혈증, 저나트륨혈증</li>
                    <li>심혈관 질환: 부정맥</li>
                  </ul>

                  <h3 class="sub-header">갑상선 검사</h3>
                  <ul class="list">
                    <li>갑상선기능항진증, 갑상선기능저하증</li>
                    <li>갑상선염</li>
                  </ul>

                  <h3 class="sub-header">간기능 검사</h3>
                  <ul class="list">
                    <li>간염: A형, B형, C형</li>
                    <li>간경변, 지방간</li>
                    <li>간암</li>
                  </ul>

                  <h3 class="sub-header">성병 검사</h3>
                  <ul class="list">
                    <li>매독, HIV, 임질, 클라미디아</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">일반 검사</h2>

                  <h3 class="sub-header">기본진료</h3>
                  <ul class="list">
                    <li>고혈압, 당뇨, 비만, 고지혈증</li>
                  </ul>

                  <h3 class="sub-header">안과</h3>
                  <ul class="list">
                    <li>백내장, 녹내장, 황반변성</li>
                  </ul>

                  <h3 class="sub-header">이과</h3>
                  <ul class="list">
                    <li>중이염, 난청, 메니에르병</li>
                  </ul>

                  <h3 class="sub-header">순환기</h3>
                  <ul class="list">
                    <li>고혈압, 심부전, 관상동맥질환</li>
                  </ul>

                  <h3 class="sub-header">심폐기능 검사</h3>
                  <ul class="list">
                    <li>폐기능 저하: 만성폐쇄성폐질환(COPD), 천식</li>
                    <li>심장질환: 협심증, 심근경색</li>
                  </ul>

                  <h3 class="sub-header">골밀도 검사</h3>
                  <ul class="list">
                    <li>골다공증, 골감소증</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">여성 건강</h2>

                  <h3 class="sub-header">자궁경부세포 검사</h3>
                  <ul class="list">
                    <li>자궁경부암, 자궁경부염</li>
                  </ul>

                  <h3 class="sub-header">유방촬영술</h3>
                  <ul class="list">
                    <li>유방암, 유방낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">소변 검사</h2>

                  <h3 class="sub-header">소변검사</h3>
                  <ul class="list">
                    <li>요로감염, 신장염, 당뇨병</li>
                    <li>단백뇨, 혈뇨</li>
                  </ul>

                  <h3 class="sub-header">요침사현미경 검사</h3>
                  <ul class="list">
                    <li>신장결석, 사구체신염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">근골격계 검사</h2>
                  <ul class="list">
                    <li>관절염, 골관절염</li>
                    <li>근막통증증후군</li>
                  </ul>
                </div>

                <p class="note">
                  위의 질환 목록은 검사 결과에 따라 의심되거나 진단될 수 있는 대표적인 질병들입니다. 정확한 진단은
                  임상의와 상담을 통해 이루어져야 합니다.
                </p>
              </div>
            ) : data.product_1 === '예비부부 패키지 2인' || data.product === '예비부부 패키지 2인' ? (
              <div class="disease-info">
                <h1 class="title">검사별로 확인 가능한 핵심 질병</h1>

                <div class="section">
                  <h2 class="section-header">영상 검사</h2>

                  <h3 class="sub-header">CT</h3>
                  <ul class="list">
                    <li>폐질환: 폐암, 폐렴, 폐기종</li>
                    <li>복부질환: 간암, 췌장암, 신장암, 담석증</li>
                    <li>뇌질환: 두부 외상, 뇌출혈</li>
                    <li>골격질환: 골절, 척추질환</li>
                  </ul>

                  <h3 class="sub-header">일반초음파</h3>
                  <ul class="list">
                    <li>갑상선: 갑상선 결절, 갑상선암</li>
                    <li>복부: 간암, 신장암, 췌장암</li>
                    <li>여성 건강: 자궁근종, 난소낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">내시경 검사</h2>

                  <h3 class="sub-header">위내시경</h3>
                  <ul class="list">
                    <li>위염, 위궤양, 위암, 헬리코박터 감염</li>
                  </ul>

                  <h3 class="sub-header">대장내시경</h3>
                  <ul class="list">
                    <li>대장용종, 대장암, 크론병, 궤양성 대장염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">혈액 검사 및 암표지자</h2>

                  <h3 class="sub-header">일반혈액검사</h3>
                  <ul class="list">
                    <li>빈혈: 철결핍성 빈혈, 재생불량성 빈혈</li>
                    <li>염증반응: 감염, 류마티스 관절염</li>
                  </ul>

                  <h3 class="sub-header">염증반응 검사</h3>
                  <ul class="list">
                    <li>전신 염증: 패혈증, 감염성 질환</li>
                    <li>자가면역질환: 루푸스, 류마티스 질환</li>
                  </ul>

                  <h3 class="sub-header">췌장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 췌장염</li>
                    <li>췌장암</li>
                  </ul>

                  <h3 class="sub-header">당뇨 검사</h3>
                  <ul class="list">
                    <li>당뇨병: 제1형/제2형 당뇨병</li>
                    <li>당뇨 합병증: 당뇨병성 신증</li>
                  </ul>

                  <h3 class="sub-header">신장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 신부전</li>
                    <li>신장염, 요로감염</li>
                  </ul>

                  <h3 class="sub-header">전해질 검사</h3>
                  <ul class="list">
                    <li>전해질 불균형: 저칼륨혈증, 저나트륨혈증</li>
                    <li>심혈관 질환: 부정맥</li>
                  </ul>

                  <h3 class="sub-header">갑상선 검사</h3>
                  <ul class="list">
                    <li>갑상선기능항진증, 갑상선기능저하증</li>
                    <li>갑상선염</li>
                  </ul>

                  <h3 class="sub-header">간기능 검사</h3>
                  <ul class="list">
                    <li>간염: A형, B형, C형</li>
                    <li>간경변, 지방간</li>
                    <li>간암</li>
                  </ul>

                  <h3 class="sub-header">성병 검사</h3>
                  <ul class="list">
                    <li>매독, HIV, 임질, 클라미디아</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">일반 검사</h2>

                  <h3 class="sub-header">기본진료</h3>
                  <ul class="list">
                    <li>고혈압, 당뇨, 비만, 고지혈증</li>
                  </ul>

                  <h3 class="sub-header">안과</h3>
                  <ul class="list">
                    <li>백내장, 녹내장, 황반변성</li>
                  </ul>

                  <h3 class="sub-header">이과</h3>
                  <ul class="list">
                    <li>중이염, 난청, 메니에르병</li>
                  </ul>

                  <h3 class="sub-header">순환기</h3>
                  <ul class="list">
                    <li>고혈압, 심부전, 관상동맥질환</li>
                  </ul>

                  <h3 class="sub-header">심폐기능 검사</h3>
                  <ul class="list">
                    <li>폐기능 저하: 만성폐쇄성폐질환(COPD), 천식</li>
                    <li>심장질환: 협심증, 심근경색</li>
                  </ul>

                  <h3 class="sub-header">골밀도 검사</h3>
                  <ul class="list">
                    <li>골다공증, 골감소증</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">여성 건강</h2>

                  <h3 class="sub-header">자궁경부세포 검사</h3>
                  <ul class="list">
                    <li>자궁경부암, 자궁경부염</li>
                  </ul>

                  <h3 class="sub-header">유방촬영술</h3>
                  <ul class="list">
                    <li>유방암, 유방낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">소변 검사</h2>

                  <h3 class="sub-header">소변검사</h3>
                  <ul class="list">
                    <li>요로감염, 신장염, 당뇨병</li>
                    <li>단백뇨, 혈뇨</li>
                  </ul>

                  <h3 class="sub-header">요침사현미경 검사</h3>
                  <ul class="list">
                    <li>신장결석, 사구체신염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">근골격계 검사</h2>
                  <ul class="list">
                    <li>관절염, 골관절염</li>
                    <li>근막통증증후군</li>
                  </ul>
                </div>

                <p class="note">
                  위의 질환 목록은 검사 결과에 따라 의심되거나 진단될 수 있는 대표적인 질병들입니다. 정확한 진단은
                  임상의와 상담을 통해 이루어져야 합니다.
                </p>
              </div>
            ) : data.product_1 === '효도검진 패키지 1인' || data.product === '효도검진 패키지 1인' ? (
              <div class="disease-info">
                <h1 class="title">검사별로 확인 가능한 핵심 질병</h1>

                <div class="section">
                  <h2 class="section-header">영상 검사</h2>

                  <h3 class="sub-header">CT</h3>
                  <ul class="list">
                    <li>폐질환: 폐암, 폐렴, 폐기종</li>
                    <li>복부질환: 간암, 췌장암, 신장암, 담석증</li>
                    <li>뇌질환: 두부 외상, 뇌출혈</li>
                    <li>골격질환: 골절, 척추질환</li>
                  </ul>

                  <h3 class="sub-header">특수초음파</h3>
                  <ul class="list">
                    <li>심혈관: 심장판막질환, 심근경색, 심부전</li>
                    <li>혈관: 동맥류, 하지정맥류</li>
                    <li>복부: 간경변, 담낭염</li>
                  </ul>

                  <h3 class="sub-header">일반초음파</h3>
                  <ul class="list">
                    <li>갑상선: 갑상선 결절, 갑상선암</li>
                    <li>복부: 간암, 신장암, 췌장암</li>
                    <li>여성 건강: 자궁근종, 난소낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">내시경 검사</h2>

                  <h3 class="sub-header">위내시경</h3>
                  <ul class="list">
                    <li>위염, 위궤양, 위암, 헬리코박터 감염</li>
                  </ul>

                  <h3 class="sub-header">대장내시경</h3>
                  <ul class="list">
                    <li>대장용종, 대장암, 크론병, 궤양성 대장염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">혈액 검사 및 암표지자</h2>

                  <h3 class="sub-header">암표지자 검사</h3>
                  <ul class="list">
                    <li>간암: AFP</li>
                    <li>췌장암: CA19-9</li>
                    <li>대장암: CEA</li>
                    <li>유방암: CA15-3</li>
                    <li>난소암: CA125</li>
                    <li>폐암: Cyfra 21-1</li>
                  </ul>

                  <h3 class="sub-header">일반혈액검사</h3>
                  <ul class="list">
                    <li>빈혈: 철결핍성 빈혈, 재생불량성 빈혈</li>
                    <li>염증반응: 감염, 류마티스 관절염</li>
                  </ul>

                  <h3 class="sub-header">염증반응 검사</h3>
                  <ul class="list">
                    <li>전신 염증: 패혈증, 감염성 질환</li>
                    <li>자가면역질환: 루푸스, 류마티스 질환</li>
                  </ul>

                  <h3 class="sub-header">췌장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 췌장염</li>
                    <li>췌장암</li>
                  </ul>

                  <h3 class="sub-header">당뇨 검사</h3>
                  <ul class="list">
                    <li>당뇨병: 제1형/제2형 당뇨병</li>
                    <li>당뇨 합병증: 당뇨병성 신증</li>
                  </ul>

                  <h3 class="sub-header">신장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 신부전</li>
                    <li>신장염, 요로감염</li>
                  </ul>

                  <h3 class="sub-header">전해질 검사</h3>
                  <ul class="list">
                    <li>전해질 불균형: 저칼륨혈증, 저나트륨혈증</li>
                    <li>심혈관 질환: 부정맥</li>
                  </ul>

                  <h3 class="sub-header">갑상선 검사</h3>
                  <ul class="list">
                    <li>갑상선기능항진증, 갑상선기능저하증</li>
                    <li>갑상선염</li>
                  </ul>

                  <h3 class="sub-header">간기능 검사</h3>
                  <ul class="list">
                    <li>간염: A형, B형, C형</li>
                    <li>간경변, 지방간</li>
                    <li>간암</li>
                  </ul>

                  <h3 class="sub-header">성병 검사</h3>
                  <ul class="list">
                    <li>매독, HIV, 임질, 클라미디아</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">일반 검사</h2>

                  <h3 class="sub-header">기본진료</h3>
                  <ul class="list">
                    <li>고혈압, 당뇨, 비만, 고지혈증</li>
                  </ul>

                  <h3 class="sub-header">안과</h3>
                  <ul class="list">
                    <li>백내장, 녹내장, 황반변성</li>
                  </ul>

                  <h3 class="sub-header">이과</h3>
                  <ul class="list">
                    <li>중이염, 난청, 메니에르병</li>
                  </ul>

                  <h3 class="sub-header">순환기</h3>
                  <ul class="list">
                    <li>고혈압, 심부전, 관상동맥질환</li>
                  </ul>

                  <h3 class="sub-header">심폐기능 검사</h3>
                  <ul class="list">
                    <li>폐기능 저하: 만성폐쇄성폐질환(COPD), 천식</li>
                    <li>심장질환: 협심증, 심근경색</li>
                  </ul>

                  <h3 class="sub-header">골밀도 검사</h3>
                  <ul class="list">
                    <li>골다공증, 골감소증</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">여성 건강</h2>

                  <h3 class="sub-header">자궁경부세포 검사</h3>
                  <ul class="list">
                    <li>자궁경부암, 자궁경부염</li>
                  </ul>

                  <h3 class="sub-header">유방촬영술</h3>
                  <ul class="list">
                    <li>유방암, 유방낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">소변 검사</h2>

                  <h3 class="sub-header">소변검사</h3>
                  <ul class="list">
                    <li>요로감염, 신장염, 당뇨병</li>
                    <li>단백뇨, 혈뇨</li>
                  </ul>

                  <h3 class="sub-header">요침사현미경 검사</h3>
                  <ul class="list">
                    <li>신장결석, 사구체신염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">근골격계 검사</h2>
                  <ul class="list">
                    <li>관절염, 골관절염</li>
                    <li>근막통증증후군</li>
                  </ul>
                </div>

                <p class="note">
                  위의 질환 목록은 검사 결과에 따라 의심되거나 진단될 수 있는 대표적인 질병들입니다. 정확한 진단은
                  임상의와 상담을 통해 이루어져야 합니다.
                </p>
              </div>
            ) : data.product_1 === '효도검진 패키지 2인' || data.product === '효도검진 패키지 2인' ? (
              <div class="disease-info">
                <h1 class="title">검사별로 확인 가능한 핵심 질병</h1>

                <div class="section">
                  <h2 class="section-header">영상 검사</h2>

                  <h3 class="sub-header">CT</h3>
                  <ul class="list">
                    <li>폐질환: 폐암, 폐렴, 폐기종</li>
                    <li>복부질환: 간암, 췌장암, 신장암, 담석증</li>
                    <li>뇌질환: 두부 외상, 뇌출혈</li>
                    <li>골격질환: 골절, 척추질환</li>
                  </ul>

                  <h3 class="sub-header">특수초음파</h3>
                  <ul class="list">
                    <li>심혈관: 심장판막질환, 심근경색, 심부전</li>
                    <li>혈관: 동맥류, 하지정맥류</li>
                    <li>복부: 간경변, 담낭염</li>
                  </ul>

                  <h3 class="sub-header">일반초음파</h3>
                  <ul class="list">
                    <li>갑상선: 갑상선 결절, 갑상선암</li>
                    <li>복부: 간암, 신장암, 췌장암</li>
                    <li>여성 건강: 자궁근종, 난소낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">내시경 검사</h2>

                  <h3 class="sub-header">위내시경</h3>
                  <ul class="list">
                    <li>위염, 위궤양, 위암, 헬리코박터 감염</li>
                  </ul>

                  <h3 class="sub-header">대장내시경</h3>
                  <ul class="list">
                    <li>대장용종, 대장암, 크론병, 궤양성 대장염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">혈액 검사 및 암표지자</h2>

                  <h3 class="sub-header">암표지자 검사</h3>
                  <ul class="list">
                    <li>간암: AFP</li>
                    <li>췌장암: CA19-9</li>
                    <li>대장암: CEA</li>
                    <li>유방암: CA15-3</li>
                    <li>난소암: CA125</li>
                    <li>폐암: Cyfra 21-1</li>
                  </ul>

                  <h3 class="sub-header">일반혈액검사</h3>
                  <ul class="list">
                    <li>빈혈: 철결핍성 빈혈, 재생불량성 빈혈</li>
                    <li>염증반응: 감염, 류마티스 관절염</li>
                  </ul>

                  <h3 class="sub-header">염증반응 검사</h3>
                  <ul class="list">
                    <li>전신 염증: 패혈증, 감염성 질환</li>
                    <li>자가면역질환: 루푸스, 류마티스 질환</li>
                  </ul>

                  <h3 class="sub-header">췌장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 췌장염</li>
                    <li>췌장암</li>
                  </ul>

                  <h3 class="sub-header">당뇨 검사</h3>
                  <ul class="list">
                    <li>당뇨병: 제1형/제2형 당뇨병</li>
                    <li>당뇨 합병증: 당뇨병성 신증</li>
                  </ul>

                  <h3 class="sub-header">신장기능 검사</h3>
                  <ul class="list">
                    <li>급성/만성 신부전</li>
                    <li>신장염, 요로감염</li>
                  </ul>

                  <h3 class="sub-header">전해질 검사</h3>
                  <ul class="list">
                    <li>전해질 불균형: 저칼륨혈증, 저나트륨혈증</li>
                    <li>심혈관 질환: 부정맥</li>
                  </ul>

                  <h3 class="sub-header">갑상선 검사</h3>
                  <ul class="list">
                    <li>갑상선기능항진증, 갑상선기능저하증</li>
                    <li>갑상선염</li>
                  </ul>

                  <h3 class="sub-header">간기능 검사</h3>
                  <ul class="list">
                    <li>간염: A형, B형, C형</li>
                    <li>간경변, 지방간</li>
                    <li>간암</li>
                  </ul>

                  <h3 class="sub-header">성병 검사</h3>
                  <ul class="list">
                    <li>매독, HIV, 임질, 클라미디아</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">일반 검사</h2>

                  <h3 class="sub-header">기본진료</h3>
                  <ul class="list">
                    <li>고혈압, 당뇨, 비만, 고지혈증</li>
                  </ul>

                  <h3 class="sub-header">안과</h3>
                  <ul class="list">
                    <li>백내장, 녹내장, 황반변성</li>
                  </ul>

                  <h3 class="sub-header">이과</h3>
                  <ul class="list">
                    <li>중이염, 난청, 메니에르병</li>
                  </ul>

                  <h3 class="sub-header">순환기</h3>
                  <ul class="list">
                    <li>고혈압, 심부전, 관상동맥질환</li>
                  </ul>

                  <h3 class="sub-header">심폐기능 검사</h3>
                  <ul class="list">
                    <li>폐기능 저하: 만성폐쇄성폐질환(COPD), 천식</li>
                    <li>심장질환: 협심증, 심근경색</li>
                  </ul>

                  <h3 class="sub-header">골밀도 검사</h3>
                  <ul class="list">
                    <li>골다공증, 골감소증</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">여성 건강</h2>

                  <h3 class="sub-header">자궁경부세포 검사</h3>
                  <ul class="list">
                    <li>자궁경부암, 자궁경부염</li>
                  </ul>

                  <h3 class="sub-header">유방촬영술</h3>
                  <ul class="list">
                    <li>유방암, 유방낭종</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">소변 검사</h2>

                  <h3 class="sub-header">소변검사</h3>
                  <ul class="list">
                    <li>요로감염, 신장염, 당뇨병</li>
                    <li>단백뇨, 혈뇨</li>
                  </ul>

                  <h3 class="sub-header">요침사현미경 검사</h3>
                  <ul class="list">
                    <li>신장결석, 사구체신염</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">근골격계 검사</h2>
                  <ul class="list">
                    <li>관절염, 골관절염</li>
                    <li>근막통증증후군</li>
                  </ul>
                </div>

                <p class="note">
                  위의 질환 목록은 검사 결과에 따라 의심되거나 진단될 수 있는 대표적인 질병들입니다. 정확한 진단은
                  임상의와 상담을 통해 이루어져야 합니다.
                </p>
              </div>
            ) : data.product_1 === '페이스 라인업 수분 케어' || data.product === '페이스 라인업 수분 케어' ? (
              <div class="disease-info">
                {/* <h1 class="title">페이스라인업 수분케어</h1> */}

                <div class="section">
                  <h2 class="section-header">추천 대상</h2>
                  <ul class="list">
                    <li>피부가 건조하고 탄력이 떨어진 경우.</li>
                    <li>얼굴 윤곽이 뚜렷하지 않거나 부종이 있는 경우.</li>
                    <li>잦은 화장과 외부 자극으로 인해 피부가 손상된 경우.</li>
                  </ul>
                </div>
              </div>
            ) : data.product_1 === '백옥주사' || data.product === '백옥주사' ? (
              <div class="disease-info">
                <h1 class="title">백옥주사의 효과</h1>

                <div class="section">
                  <h2 class="section-header">피부 미백</h2>
                  <ul class="list">
                    <li>멜라닌 생성을 억제하여 피부톤을 밝게 하고 균일하게 만듦.</li>
                    <li>기미, 주근깨 등 색소 침착 완화.</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">항산화 효과</h2>
                  <ul class="list">
                    <li>활성산소를 제거하여 세포 손상을 예방.</li>
                    <li>피부 노화 방지와 염증 완화.</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">피부 탄력 개선</h2>
                  <ul class="list">
                    <li>콜라겐 생성 촉진으로 피부 탄력 증대.</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">디톡스 효과</h2>
                  <ul class="list">
                    <li>간 기능 개선 및 체내 독소 배출을 도와 건강 유지.</li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">면역력 강화</h2>
                  <ul class="list">
                    <li>면역 체계 강화 및 피로 회복.</li>
                  </ul>
                </div>

                <h1 class="title">백옥주사 추천 대상</h1>

                <div class="section">
                  <ul class="list">
                    <li>칙칙하고 어두운 피부톤이 고민인 경우.</li>
                    <li>기미, 주근깨와 같은 색소침착을 완화하고 싶은 경우.</li>
                    <li>피로 회복 및 항산화 관리를 원하는 경우.</li>
                    <li>간 건강을 개선하고 싶거나 디톡스 효과를 기대하는 경우.</li>
                  </ul>
                </div>
              </div>
            ) : data.product_1 === '텐써마' || data.product === '텐써마' ? (
              <div class="disease-info">
                {/* <h1 class="title">텐써마</h1> */}

                <div class="section">
                  <h2 class="section-header">1. 텐써마란?</h2>
                  <ul class="list">
                    <li>
                      <strong>기술 원리:</strong> 고주파(RF, Radio Frequency) 에너지를 피부 진피층 깊숙이 전달해 콜라겐
                      재생을 유도.
                    </li>
                    <li>
                      <strong>핵심 효과:</strong> 피부 리프팅, 주름 개선, 얼굴 윤곽 정리.
                    </li>
                    <li>
                      <strong>비침습적 시술:</strong> 칼을 대지 않는 안전한 리프팅 기법.
                    </li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">2. 텐써마의 주요 효과</h2>
                  <ul class="list">
                    <li>
                      <strong>피부 리프팅:</strong> 피부를 타이트하게 당겨 얼굴 윤곽을 매끄럽게 만듦.
                    </li>
                    <li>
                      <strong>주름 개선:</strong> 이마, 눈가, 입가 등의 잔주름과 깊은 주름을 개선.
                    </li>
                    <li>
                      <strong>탄력 증가:</strong> 콜라겐 생성 촉진으로 피부 탄력을 회복.
                    </li>
                    <li>
                      <strong>모공 축소:</strong> 피부결 개선으로 매끄럽고 촘촘한 피부 연출.
                    </li>
                    <li>
                      <strong>피부 두께 강화:</strong> 피부가 얇아지며 생긴 처짐 현상 개선.
                    </li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">3. 텐써마 추천 대상</h2>
                  <ul class="list">
                    <li>피부가 처지기 시작한 30대~50대.</li>
                    <li>주름 개선 및 피부 탄력 회복을 원하는 사람.</li>
                    <li>얼굴 윤곽 정리를 원하는 사람.</li>
                    <li>칼을 대지 않고 리프팅 효과를 보고 싶은 사람.</li>
                  </ul>
                </div>
              </div>
            ) : data.product_1 === '드레스 라인 보톡스' || data.product === '드레스 라인 보톡스' ? (
              <div class="disease-info">
                {/* <h1 class="title">드레스라인 보톡스</h1> */}

                <div class="section">
                  <h2 class="section-header">1. 드레스라인 보톡스란?</h2>
                  <ul class="list">
                    <li>
                      <strong>시술 부위:</strong> 승모근(어깨 근육).
                    </li>
                    <li>
                      <strong>사용 물질:</strong> 보툴리눔 톡신(Botulinum Toxin, 보톡스).
                    </li>
                    <li>
                      <strong>목적:</strong> 승모근 근육을 이완시켜 크기를 줄이고 어깨 라인을 슬림하고 부드럽게 만들어
                      줌. 자세 개선 효과.
                    </li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">2. 추천 대상</h2>
                  <ul class="list">
                    <li>승모근이 발달하여 어깨가 두꺼워 보이는 경우.</li>
                    <li>목이 짧아 보이거나 승모근 부위가 지나치게 돌출된 경우.</li>
                    <li>날씬한 어깨 라인과 길고 우아한 목선을 원하는 경우.</li>
                    <li>드레스, 민소매 등에서 부드러운 실루엣을 강조하고 싶은 경우.</li>
                  </ul>
                </div>
              </div>
            ) : data.product_1 === '항노화-오투셀(광양자치료)' || data.product === '항노화-오투셀(광양자치료)' ? (
              <div class="disease-info">
                {/* <h1 class="title">항노화</h1> */}

                <div class="section">
                  <h2 class="section-header">효과</h2>
                  <ul class="list">
                    <li>
                      <strong>면역력 강화:</strong> 백혈구의 활동성을 높여 감염 예방에 도움을 줍니다.
                    </li>
                    <li>
                      <strong>혈액순환 개선:</strong> 적혈구의 응집 현상을 완화하여 혈류를 원활하게 합니다.
                    </li>
                    <li>
                      <strong>항산화 작용:</strong> 활성산소를 제거하여 세포 손상을 방지하고 노화를 지연시킵니다.
                    </li>
                    <li>
                      <strong>피로 회복:</strong> 에너지 대사를 촉진하여 만성 피로를 개선합니다.
                    </li>
                  </ul>
                </div>
              </div>
            ) : data.product_1 === '더마샤인(리쥬란+물광주사+스킨보톡스)' ||
              data.product === '더마샤인(리쥬란+물광주사+스킨보톡스)' ? (
              <div class="disease-info">
                {/* <h1 class="title">더마샤인</h1> */}

                <div class="section">
                  <h2 class="section-header">1. 더마샤인이란?</h2>
                  <ul class="list">
                    <li>
                      <strong>더마샤인 장비:</strong> 피부에 미세한 바늘(5~9핀)을 이용해 균일한 깊이로 약물을 주입하는
                      자동 장비.
                    </li>
                    <li>
                      <strong>효과:</strong> 피부 깊은 곳까지 약물을 전달하여 전체적인 피부 컨디션 개선. 시술 시간이
                      짧고, 약물 주입의 균일성이 높음.
                    </li>
                    <li>
                      <strong>주요 목적:</strong> 수분 공급, 탄력 회복, 피부결 개선.
                    </li>
                  </ul>
                </div>

                <div class="section">
                  <h2 class="section-header">2. 주요 시술 옵션</h2>

                  <div class="sub-section">
                    <h3 class="sub-header">(1) 리쥬란 힐러</h3>
                    <ul class="list">
                      <li>
                        <strong>설명:</strong> 연어 DNA(폴리뉴클레오타이드, PN) 성분을 주입하여 피부 재생과 탄력을 개선.
                      </li>
                      <li>
                        <strong>효과:</strong> 피부 치밀도 증가로 피부 탄력 개선. 손상된 피부 회복 및 주름 완화. 피부톤
                        개선 및 모공 축소.
                      </li>
                      <li>
                        <strong>추천 대상:</strong> 피부 노화로 탄력이 감소한 경우. 피부 장벽 강화와 손상 피부 개선이
                        필요한 경우.
                      </li>
                    </ul>
                  </div>

                  <div class="sub-section">
                    <h3 class="sub-header">(2) 물광주사</h3>
                    <ul class="list">
                      <li>
                        <strong>설명:</strong> 히알루론산(HA)을 주입하여 피부에 직접적으로 수분을 공급.
                      </li>
                      <li>
                        <strong>효과:</strong> 촉촉한 피부 연출. 피부 결 개선 및 광채 부여. 미세 주름 개선.
                      </li>
                      <li>
                        <strong>추천 대상:</strong> 건조하고 칙칙한 피부. 즉각적인 수분 공급이 필요한 경우.
                      </li>
                    </ul>
                  </div>

                  <div class="sub-section">
                    <h3 class="sub-header">(3) 스킨보톡스</h3>
                    <ul class="list">
                      <li>
                        <strong>설명:</strong> 보툴리눔 톡신을 피부에 얕게 주입하여 잔주름을 개선하고 탄력을 증가.
                      </li>
                      <li>
                        <strong>효과:</strong> 모공 축소. 잔주름 제거 및 피부 결 정돈. 자연스러운 리프팅 효과.
                      </li>
                      <li>
                        <strong>추천 대상:</strong> 늘어난 모공과 피부 탄력 저하. 잔주름 개선을 원하는 경우.
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="section">
                  <h2 class="section-header">3. 추천 대상</h2>
                  <ul class="list">
                    <li>건조하거나 탄력을 잃은 피부.</li>
                    <li>잔주름, 모공 확장, 칙칙한 피부톤 개선을 원하는 경우.</li>
                    <li>피부 재생이 필요한 손상 피부.</li>
                    <li>피부에 광채와 매끄러움을 부여하고 싶은 경우.</li>
                  </ul>
                </div>
              </div>
            ) : data.product_1 === '상담후 진료(신부패키지)' || data.product === '상담후 진료(신부패키지)' ? (
              <div class="disease-info">
                <h1 class="title">뷰티상담</h1>

                <div class="section">
                  <p>
                    성형, 피부, 두피, 줄기세포, 재활의학 등 다양한 연령층 모두가 내원하여 자신에게 맞는 힐링뷰티를
                    만나볼 수 있습니다.
                  </p>
                </div>

                <div class="section">
                  <h2 class="section-header">성형</h2>
                  <p>
                    획일화된 아름다움이 아닌, 나에게 꼭 맞는 성형을 맞춤 디자인 해드리며, 꼭 손대지 않아도 될 부분은
                    환자분이 원하신다고 해도 권하지 않습니다. 성형의 원래 목적은 인공적인 아름다움을 넣어 부조화를
                    만드는 것이 아닌, 가장 나다운 아름다운 매력을 주는 것입니다.
                  </p>
                </div>

                <div class="section">
                  <h2 class="section-header">피부</h2>
                  <p>
                    색소, 리프팅, 모공 등 나의 피부타입에 맞는 진단을 통해 타 곳에서 만날 수 없는 수준 높은 의료서비스를
                    만나보실 수 있습니다.
                  </p>
                  <p>
                    대부분의 피부과는 눈에 보이는 증상만 진단합니다. 하지만 메디코드의 협력병원은 눈에 보이지 않는
                    곳까지 진단하여, 근본적인 맞춤 치료를 처방합니다.
                  </p>
                </div>

                <div class="section">
                  <h2 class="section-header">줄기세포</h2>
                  <p>
                    줄기세포는 여러 종류의 세포로 분화할 수 있는 미분화 세포로, 본원에서는 치료활용 가능한
                    성체줄기세포를 사용합니다. 관련 치료는 손상된 혈관을 재생하고 신진대사 혈액공급을 원활히 하여
                    성인병과 대사질환 치료에 효과적입니다.
                  </p>
                  <p>
                    또한 면역관련 조직세포를 재생해 면역력을 강화시켜 바이러스 예방이 가능하며, 정맥, 피부, 두피 시술을
                    통해 손상된 세포를 재생하여 노화를 지연시키는 효과를 기대할 수 있습니다. 화상, 상처로 인한 손상 재생
                    역시 상담 가능합니다.
                  </p>
                </div>

                <div class="section">
                  <h2 class="section-header">두피케어</h2>
                  <p>
                    줄기세포 전문가, 화장품 전문가, 두피관리 전문가가 모여 개발한 두피치료를 메디코드 협력병원에서
                    만나볼 수 있습니다. 개개인의 두피상태를 정밀 진단해 맞춤 처방을 진행하며, 저자극 원료를 사용해
                    안전한 두피케어를 지향합니다.
                  </p>
                  <p>IT 적정기술과 결합해 전류자극 없이 안전하게 두피 건강을 되살리는 프로그램을 제공합니다.</p>
                </div>
              </div>
            ) : data.product_1 === '상담후 진료(효도상품)' || data.product === '상담후 진료(효도상품)' ? (
              <div class="disease-info">
                <h1 class="title">항노화상담</h1>

                <div class="section">
                  <p>
                    메디코드의 협력병원은 최신 기술과 숙련된 의료진이 풍부한 임상경험을 바탕으로 개인맞춤형
                    줄기세포치료를 진행합니다.
                  </p>
                  <p>고도화된 장비를 통해 각 환자의 상태에 맞는 정밀한 진단을 하고 최적화된 치료계획을 수립합니다.</p>
                  <p>
                    편안하고 안전한 진료환경과 입원실을 제공하며 의료진의 세심한 케어를 받으실 수 있도록 체계적인
                    시스템이 갖추어져 있습니다.
                  </p>
                </div>

                <div class="section">
                  <h2 class="section-header">‘웰빙뷰티를 지향하는 줄기세포치료’</h2>
                  <p>단순한 의료 서비스를 넘어 웰빙뷰티 실현을 목표로 하고 있습니다.</p>
                  <p>신체적 건강과 함께 정신적 안정, 자신감 회복, 삶의 활력 증진 등 전인적 웰빙을 추구하고 있습니다.</p>
                  <p>
                    외모 개선은 자연스럽게 자존감 향상으로, 자존감 향상은 대인관계와 사회생활에 긍정적으로 이어집니다.
                  </p>
                  <p>
                    미디코드의 항노화 의료서비스로 건강한 신체, 아름다운 외모, 활기찬 정신이 조화를 이루는 삶의 질을
                    만나보세요.
                  </p>
                </div>
              </div>
            ) : (
              //국가검진
              <div className={`content_img nation`}></div>
            )}
          </div>
          <div
            className="modal_footer_box"
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              background: '#fff',
              borderTop: '1px solid #ccc',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            <div
              className="modal_btn"
              style={{
                marginRight: '10px',
                background: '#fff',
                color: '#333',
                border: '1px solid #333',
              }}
              onClick={downloadFile}
            >
              검진항목 다운로드(PDF)
            </div>
            <div
              className="modal_btn"
              style={{
                marginRight: '10px',
                background: '#017AFF',
                color: '#fff',
              }}
              onClick={props.closeModal}
            >
              상품목록으로 돌아가기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailModal;
